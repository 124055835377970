import React from 'react';
export const Nav30DataSource = {
  wrapper: { className: 'header3 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header3-logo',
    children:
      'https://yihu-nsp-prod.oss-cn-hangzhou.aliyuncs.com/website/head/nav0/head-log.png',
  },
  Menu: {
    className: 'header3-menu',
    children: [
      {
        name: 'item0',
        className: 'header3-item',
        children: {
          href: '#Content11_0',
          children: [
            {
              children: (
                <span>
                  <span>
                    <p>主页</p>
                  </span>
                </span>
              ),
              name: 'text',
            },
          ],
        }
      },
      {
        name: 'item1',
        className: 'header3-item',
        children: {
          href: '#Teams1_0',
          children: [
            {
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>产品页面</p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item3',
        className: 'header3-item',
        children: {
          href: '#Content13_0',
          children: [
            {
              children: (
                <span>
                  <p>技术支持</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
    ],
  },
  mobileMenu: { className: 'header3-mobile-menu' },
};
export const Content110DataSource = {
  OverPack: {
    className: 'home-page-wrapper content11-wrapper',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          '',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <span>
            <p>好护无忧</p>
          </span>
        ),
        className: 'title-h1 kzqo6vaqde7-editor_css ',
      },
      {
        name: 'content',
        children: (
          <span>
            <p>
              订单充足的兼职护士平台<br />
            </p>
          </span>
        ),
        className: 'title-content ',
      },
      {
        name: 'content2',
        children: (
          <span>
            <p>1.操作简单。全部资料在线提交。</p>
            <p>2.订单不愁。开通城市300余个，无论你在哪，都能接单！</p>
            <p>3.订单明确。订单相关信息一目了然，时间地点需要干啥应有尽有。</p>
            <p>
              4.专属客服。护士专属客服实时在线，需要任何帮助用不着万能的小黑，找客服就成。
            </p>
          </span>
        ),
        className: 'title-content kzqo6vaqde7-editor_css',
      },
    ],
  },
  button: {
    className: '',
    children: { a: { className: 'button', href: '#', children: '立即报名' } },
  },
};
export const Teams10DataSource = {
  wrapper: { className: 'home-page-wrapper teams1-wrapper' },
  page: { className: 'home-page teams1 kzqolckptqe-editor_css' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <span>
                <p>产品页面</p>
              </span>
            </span>
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image kzqojs1hscf-editor_css',
              children:
                'https://yihu-nsp-prod.oss-cn-hangzhou.aliyuncs.com/website/temp/1.jpeg',
            },
            {
              name: 'title',
              className: 'teams1-title',
              children: (
                <span>
                  <p>
                    <br />
                  </p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'teams1-job',
              children: (
                <span>
                  <p>
                    <br />
                  </p>
                </span>
              ),
            },
            {
              name: 'content1',
              className: 'teams1-content',
              children: (
                <span>
                  <p>
                    <br />
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image kzqolm286ig-editor_css',
              children:
                'https://yihu-nsp-prod.oss-cn-hangzhou.aliyuncs.com/website/temp/2.jpeg',
            },
            {
              name: 'title',
              className: 'teams1-title',
              children: (
                <span>
                  <span>
                    <p>
                      <br />
                    </p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'teams1-job',
              children: (
                <span>
                  <p>
                    <br />
                  </p>
                </span>
              ),
            },
            {
              name: 'content1',
              className: 'teams1-content',
              children: (
                <span>
                  <p>
                    <br />
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image kzqomxacazu-editor_css',
              children:
                'https://yihu-nsp-prod.oss-cn-hangzhou.aliyuncs.com/website/temp/3.jpeg',
            },
            {
              name: 'title',
              className: 'teams1-title',
              children: (
                <span>
                  <p>
                    <br />
                  </p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'teams1-job',
              children: (
                <span>
                  <p>
                    <br />
                  </p>
                </span>
              ),
            },
            {
              name: 'content1',
              className: 'teams1-content',
              children: (
                <span>
                  <p>
                    <br />
                  </p>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Content130DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          '',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <span>
            <p>技术支持</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <span>
            <span>
              <p>服务热线</p>
              <p>
                021-80399451<br />
              </p>
            </span>
          </span>
        ),
        className: 'title-content',
      },
      {
        name: 'content2',
        children: (
          <span>
            <p>为你答疑解难</p>
          </span>
        ),
        className: 'title-content',
      },
    ],
  },
};

export const Footer00DataSource = {
  wrapper: { className: 'home-page-wrapper footer0-wrapper' },
  OverPack: { className: 'home-page footer0', playScale: 0.05 },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        沪ICP备18008660号-1<br />
      </span>
    ),
  },
};
